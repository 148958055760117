import { useEffect, useState } from "react"
import fallbackData from "./alt-redirect.json"
import { getAltRedirectS3 } from "../../endpointManager"

const S3_JSON_URL = getAltRedirectS3()

export function useAltRedirectHook<T>() {
  const [altRedirectCampaign, setData] = useState<T | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(S3_JSON_URL, {
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        })
        if (!response.ok)
          throw new Error("Erro ao carregar JSON " + JSON.stringify(response))

        console.log("response s3", JSON.stringify(response))
        const jsonData: T = await response.json()
        setData(jsonData)
        console.log("alt-redirect lido com sucesso")
      } catch (err) {
        console.error(
          "Erro ao ler o alt-redirect.json do S3 -> " + S3_JSON_URL,
          err
        )
        setData(fallbackData as T)
        setError((err as Error).message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return { altRedirectCampaign, loading, error }
}
